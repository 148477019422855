import React from 'react'
import Hero_section from '../components/common/Herosection'
import { FaArrowRight } from "react-icons/fa";

const OurInvestors = () => {



  const data = {
    title: "Investor Relations",
    para: "leading online platform for business formation in the United States. Driven by a mission to unleash entrepreneurship, LegalZoom delivers comprehensive legal, tax and compliance products and expertise for small business owners through easy-to-use technology.",
    backimg:  "https://s3-alpha-sig.figma.com/img/56d6/da59/b5cadb0b27556e4fb39fb8327f9b0546?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XGq3bJ4deo8tmvNgTUfoNpeclBNh9yNA-7it80cOUIrmUHws4egSthXYdmhVyYzu9tzYx-T8Wbk3JraQ~2iqqV6ANr7QsNuWW4tPKv5nVTnT~Wj614Sl6aU8eLN5sm2wKu7Vmbhp8UTWVTeR80MKDMshJ67U2Zmn5UOvcuPux1goU2xSkvUolAqnB-f2Dc3v1YIbnCFs6R5Vh9T7nRbubrMpHk4X4d4cHN2OnjdsN21RAFndGnqIPNOGADvlbawEcLDQYIMcw7lv1gtm-KYO9ir3m2g2rtV67kVGvXTHohf3aNNraZN~i17vmkoFGXHdPCr5PfDW8BqKUk443KFwBQ__",
  };

  const data_of_services = [
    {
      revenue:"4.0M",
      title:"Businesses formed since inception"
    },
    {
      revenue:"4.0M",
      title:"Businesses formed since inception"
    },
    {
      revenue:"4.0M",
      title:"Businesses formed since inception"
    },
  ]

  const highlights=['The Economic Times and Spencer Stuart recognised him as one of the 40 under Forty Business Leaders for 2016.',
'He comes recommended by Chambers & Partners 2017, 2018 and 2019 for Private Equity, Chambers & Partners 2018 and 2019 for M&A, and IFLR 1000',
'He was one of the ‘Rising Stars – 40 under 40’, and a nominee for ‘Young Achievers Award of the Year Law Firm’, for 2016.'
]

const divStyle = {
  // width: '100%', 
  // height: '532px', 
  backgroundImage: `url(https://s3-alpha-sig.figma.com/img/e6bb/df0f/4330234b40f66926684f6bd74b7a2c5e?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iH0Gt~xWp8nUaL-YKpXpHnfYAwfGAf9Q42wmtXPnci1-9k1K6ShGrzS24rDIzOp5aehEgH4IDkwJfTJILRE374sYnNiY7MnMZTHYP2vYXaqms6jr73s51sCFJlIYmGqO8tZ967pFQYOOiNzta0f9ed~VKMWRr4K9X9O2a8G1dkC8hZKzkdS2ssZ1tc8MexToJcD1jkORDvDZez2Mfe2VuIbewjrLQDvHlUmf2kBhwm01Ii20-bNflMuwQSp9rcpcocSscLbgZwvgoHebp8bBhKJUZ~7awReTwRczIh0-EEtJioXOslrHP8v~qhqZSPodzUW2rRNg2KVF9YlN6IUJXg__)`, 
  backgroundSize: 'cover', 
  backgroundPosition: 'center', 
};
  return (
    <div className='w-screen flex flex-col items-center justify-center mb-[70px]'>

<Hero_section title={data.title}backimg={data.backimg}para={data.para}/>

<div className='flex items-center justify-center flex-col mt-[70px] gap-16'>

  <h1 className='text-3xl font-bold text-customColor-blue10'>We are the leading brand in online legal services</h1>

  <div className='flex items-center gap-9'>
    {
data_of_services.map((data,index)=>(

<div className='flex flex-col justify-center items-center gap-5' key={index}>
<h1 className='text-2xl text-customColor-blue10 text-semibold'>{data.revenue}</h1>
<p className='text-xl'>{data.title}</p>
</div>
))}

  </div>

</div>
<div className=' h-[274px] w-full bg-customColor-blue9 mt-[100px] flex items-center justify-center'>

<div className='w-9/12 flex flex-col gap-5'>
  <h1 className='font-bold text-2xl text-white'>Highlights</h1>
  <div className='flex gap-5'>
    {highlights.map((data, index) => (
      <p key={index} className={`text-white flex-1 flex items-center justify-start ${index > 0 ? 'border-l border-white border-solid border-l-10' : ''} pl-5`}>
        {data}
      </p>
    ))}
  </div>
</div>




</div>

<div className='w-9/12 mt-[70px]'>
  <h1 className='text-3xl font-bold text-customColor-blue10'>Our Purpose</h1>

<div className='flex justify-between items-center gap-16'>


<p>Our recruitment process is rigorous, and we recruit talent after putting them through rigorous technical and behavioural assessments. Our doors are open to professionals with diverse skills from legal professionals to knowledge management, business development, human resources, accounting, information technology and facility management. Commitment, excellence, integrity, knowledge, respect, openness, teamwork and trust are the bedrock of how we approach our profession. They have shaped our culture, work ethic and decision-making to help us excel each day.</p>
<img className='w-[477px] h-[306px]' src="https://s3-alpha-sig.figma.com/img/43e9/5b91/2fd086d528cdcdb51c9b3fcd2ba95285?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Vmn8VIqICbdGL4WAguCmlQO2K8uuLh85hqqlFUSFZlRJ9GYXa6hDMZboSegYNIIdfvlKvc-iEKa5lI5GdgjbXCVN~jXaTlFDSJpxy7ihbQZdDugGPI6dncQpdNFNvrfuewzE50WQkRXuAsBkSBqfSw1K4cKa~kqnAqVb04fDZbMsOHu8C4NZPR-yKNj3fqjUHHpYfBm9UYe-IPH6Rn6zdhFIlxpMEO5NNVhImlqsa0d64NDJ8WZXa87SheXlQzaGf81NZGjyrIE72AcbtUhzwZPJRa3fx1c0kebRZxW7x4m9Ej9CPAiqevbySeRJ3dv6tx4Lg4C-M3MANkBAnYDG0w__" alt="" />    
</div>

</div>

<div className='flex flex-col w-9/12 mt-[70px] gap-7'>


<div className='flex flex-col gap-3' >
<h2 className='font-bold text-2xl text-customColor-blue10'>Half year 2023 results</h2>
<p>“Our strong results reflect our resilience and ability to adapt through market cycles. We continue to demonstrate our long-standing ability to meet the needs of customers around the world with high levels of reliability. For our shareholders, we intend to start distributing our first performance-linked dividend in the third quarter.”</p>
</div>


</div>

<div className='w-8/12 p-5  flex justify mt-[70px] drop-shadow-2xl shadow-2xl'>
<div className='w-[498px] h-[363px]' style={divStyle}>

<div className='relative h-[100%] w-[100%] bg-black  bg-opacity-20 flex flex-col p-4 justify-start text-white gap-4 '>
<h1 className='text-4xl font-bold'>Investor <br/> Presentation</h1>
<p>19 Jan’24</p>
</div>

</div>

<div className='flex flex-col justify-between mx-16 '>
  <div className='flex flex-col gap-3'>
  <h1 className='font-bold text-2xl text-customColor-blue10 mb-5'>Featured Documents</h1>
  <p>Q3 2023 Earnings Press Release</p>
  <p>Q3 2023 Supplemental Information</p>
  <p className='text-sm text-customColor-blue9'>Interim Report</p>
  <p className='text-sm text-customColor-blue9'>Press Release</p>
  </div>
 
 <div className='flex items-center gap-3'>
 <button className='text-2xl font-bold text-customColor-blue9'>Learn more</button>
 <FaArrowRight/>
 </div>

</div>

</div>

    </div>
  )
}

export default OurInvestors