import React from 'react';
import Card from './Card';
import Horizontal_rule from './Horizontal_rule';

const Section4 = () => {
  const data = [
    {
      id: 1,
      title: 'Corporate',
      para: 'Corporate practice is centred around transactional and legal advisory services concerning day-to-day business, regulatory issues, corporate and governance affairs',
      backimg: "https://s3-alpha-sig.figma.com/img/bb3b/7547/307df5b90672832145e6faaf8a559202?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nUaa0oCt-miG43TDEM4BQzD9Fts13kqPhQkp4I3Shoegkc0HuGs5F0zyjPwLiq-oSGuV9rI~jKyOEyVZE0cm3phza6ntBUk6y0bXl6M~sQcwpqnAcojox3pKlle-orbpJPqf0DHvr4~z4QRHBrtelG1QIozUa1T4zjp5ikMEUtZTnvwnG0IFyQYJeB~iRRxe9Di7nLztI1q6Sk1oFr0iHIguGm~LMddh6TWCmDinMu098rPlM5O9pNFWYyCvTbRrKQb4pI8LYs3SeuBGICvNzjjkp8MKDEyZNp2xx6MjwItJlzt0ibgfAGGpzHFXJovnbCN5lK8wgE3j5iawQvc1zg__"
    },
    {
      id: 2,
      title: 'Finance',
      para: 'Corporate practice is centred around transactional and legal advisory services concerning day-to-day business, regulatory issues, corporate and governance affairs',
      backimg: "https://s3-alpha-sig.figma.com/img/64fd/5cd0/e9dcd3c8a9cda52b57b6c9c1d28cee0a?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=e9iNTw7BEJyJrStHi~oOZVVQaOv0oVgM28KtAIZg4tQECUatEvPcjndjfbW5yNWeAKaJd1H6NMU08GlnS7RmRXKdNzo~OgvdrYzjhBA7e5Gme73JxKRjf3AzYznrva3qy9c64p1q~OuqbezY3GfZ-Q48MoBjESHDIjjMZn0C761rf1yFePF79jfSSM47-Woo~hiy1SZ5SRStLVp4TnkDjGo8lzCctYN8cnZoBWxGPqMJdyE6qaVLiuRnNgpQojlFDK8fJryT8aC80zjjG2gCto4zhr1Py2ufEuIQVbFcVzH5WKg01uEgrNze10~L3FutBix-OvL55yJdbiBo4TXmQg__"
    },
    {
      id: 3,
      title: 'Disputes',
      para: 'Corporate practice is centred around transactional and legal advisory services concerning day-to-day business, regulatory issues, corporate and governance affairs',
      backimg: "https://s3-alpha-sig.figma.com/img/55c8/fdab/3532f893cb5a5a91c5736b7d1ca111a9?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jwjpqO8jCbbM-X7dmPtpbRiIleepsILRivrRz1mYIXxnraJ4HZET08vvtUl7HGaYfqWQu5jraklZIm0mjJtBdN1jpdHuTaJU9KZWf8hCvKbzlkbVUfONHar0Xciw6cCFp9eyNZGTgeTXXPhGUkrr15DUf~b61NBQS4rQqYrfNstEU9lotJOY6~EOREC19SDZHiQ5y-M7kFlqzO1QIIHa5eptMct1iyS3fshTmP0eugxHpcz9pmc4NVoo5MkP5HgbhXjBG2ycHZgP6s1EDWPIT~jHGwZcPkw8XPkv9sBJAiSl9XCeRnsA8B736EazqzKvprmRxsRlQxAGZPl1WUKmAw__"
    },
  ];

  return (
    <div className="section4parent flex flex-col items-center my-[50px]">
        <div className='flex items-center gap-12'>
          <Horizontal_rule/>
        <h2 className=" font-semibold relative text-4xl">
        Practice area
      </h2>
      <Horizontal_rule/>
        </div>
      
      <div className="grid grid-cols-3 gap-10 mt-8">
        {data.map((item) => (
          <Card key={item.id} title={item.title} para={item.para} backimg={item.backimg} />
        ))}
      </div>
    </div>
  );
};

export default Section4;
