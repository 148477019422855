import React from 'react';
import Card_sector from './Card-sector';
import Horizontal_rule from './Horizontal_rule';

const Section5 = () => {
  const data = [
    {
      id: 1,
      title: 'Agriculture and agrichemicals',
      backimg: "https://s3-alpha-sig.figma.com/img/3b2b/dbdf/bfcbf95872722a56798818bda43b8acd?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qn-HA5pYw96DRIRiZfaS3GAFjqzJX5GKCLnQ1rmgmZnaYprLO-o5gQTDz-BShSVgxEE3nQ3XkjjimEgyvQ1xVuLGwQ5krmWkfx7Y3mY1GnxOQGoSk4XQU5i2uqKv9hJS67vb6KcQSd7xwPBX2HLv6-2d7xmJjZQCVf30CBdoV0CG4uWFVUUipRilghnq-jY2ow8y~U4XHiIg6LfWf-AfZGD1NXlMAsVIsoBGZ1ZFfUB~nb8y-yr9COCHcivo3GsY31CoCgEnxkJ9jNU3Hrq-JrANaVA5pi4nbe2h5-lNxaZyyN3FQuou6u2wIfhOkBytYDJnvLJdGauutc7Li74h2w__"
    },
    {
      id: 2,
      title: 'Airports and Aviation',
      backimg: "https://s3-alpha-sig.figma.com/img/6515/5dd2/9433fb0a94925dd469943bd7b0560a00?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YonU6DOuVOBk567BL1rsPI45h~Q8CvM3Da08S1OfirqWPVoqPTTx3Tm81ydZLfXcYzPmNFnelSuk~Jd0pSZPzQzpjMhHBvUgq3yx0WrpCOpE8aec3WtEZqSnG21bc1TQfr5uMSQxWMnVkJYleGH-S-CDxbVwxpII3AUohKxJu10tU-VjcFiXbXjW1eudPZ1A7-EO0BUkpam~Em6~yOK5mZjmIADtJiFbuJ-4duZ1ToBARwcejAOqG8aO4SASen5-HmyYQKHHittbQ4ny6SWNjSthPxse6z~jG57p8Wqje-lgus6uVxtGplptwNqEmt1ef3kvWaQUFVQLyQ5SZ54rKA__"
    },
    {
      id: 3,
      title: 'Automotive and electric vehicles',
      backimg: "https://s3-alpha-sig.figma.com/img/a319/5435/0d26d5ad6e40de30e4183301db1d6b2b?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ji4jB~eAEu53gxgHOBP3pwHk69B47Le79UsKJG7rCEFG0LwxYrdjOUW-p1~91QdnLCjG5cpVAEDTYXThZcjyyuBw8H2fYZVsZCbUIMajXZ9FHC8TRimzfEE7V23whqVJ2L3jvXCk46jaNfym2GYFcdNfHf9rhCqevyClH7HB8iQMqggxDyhi8wOxxjgqlhmvCvAT~33K0HhSrWuuUUlDNBuDOjCkverhdbMlIivE2jWf6wz~jsPJnjX90mjoNXKbH12kQ9~gDr3hQJKI4BEEdVAA0KUz1sKV6~kYBSaI8XMGcBow~puzjE25Iy7afva5IzNtUHr8z0wGeG~FinAH1g__"
    },
  ];

  return (
    <div className=" flex flex-col items-center my-[50px]">
     <div className='flex items-center gap-12'>
          <Horizontal_rule/>
        <h2 className=" font-semibold relative text-4xl">
      Sectors
      </h2>
      <Horizontal_rule/>
        </div>
      
      <div className="grid grid-cols-3 gap-10 mt-8">
        {data.map((item) => (
          <Card_sector key={item.id} title={item.title} backimg={item.backimg} />
        ))}
      </div>
    </div>
  );
};

export default Section5;
