import React from "react";
import Hero_section from "../components/common/Herosection";
import { useState } from "react";
import Horizontal_rule from "../components/Horizontal_rule";

const Contactus = () => {
  const data = {
    title: "Contact Us",
    para: "leading online platform for business formation in the United States. Driven by a mission to unleash entrepreneurship, LegalZoom delivers comprehensive legal, tax and compliance products and expertise for small business owners through easy-to-use technology.",
    backimg:
      "https://s3-alpha-sig.figma.com/img/56d6/da59/b5cadb0b27556e4fb39fb8327f9b0546?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XGq3bJ4deo8tmvNgTUfoNpeclBNh9yNA-7it80cOUIrmUHws4egSthXYdmhVyYzu9tzYx-T8Wbk3JraQ~2iqqV6ANr7QsNuWW4tPKv5nVTnT~Wj614Sl6aU8eLN5sm2wKu7Vmbhp8UTWVTeR80MKDMshJ67U2Zmn5UOvcuPux1goU2xSkvUolAqnB-f2Dc3v1YIbnCFs6R5Vh9T7nRbubrMpHk4X4d4cHN2OnjdsN21RAFndGnqIPNOGADvlbawEcLDQYIMcw7lv1gtm-KYO9ir3m2g2rtV67kVGvXTHohf3aNNraZN~i17vmkoFGXHdPCr5PfDW8BqKUk443KFwBQ__",
  };
  const more_abot_data=[
    {
    id:1,
    title:"Expertise",
    bgImg:"https://s3-alpha-sig.figma.com/img/6ecf/a52e/523dcd994e8ffa11798c58c2a77aff3b?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FBowkB0o8aKIZGFsBsmfvR46MowFSduDzpNAmL5TopTiA8L~QmtNAZyaXCYrSDh0c8CAIfmtUUDLVGdI40AySjfiVG07WgHpsKJfkPft89M8C4Cwh71NvXXtqI6e9HPRCmYFhzT~ojN3PicJvx~OU7H5cJk3D6rT~RZO93exQIA~pLc64N002pLRB146-gsEoO3ZBhMzbN2zsThnJ6t3Qw2mJGO0d9lFbbKD71jXPOLA3TOnySTw70BlNSBiIjkHu-snXsrILfFS5zm-ml2hkD80seTekOFY1kt4gG1HluDhTHzINHHNFfK14NwMsZZUFsrdNsgjVu8hrpGsz53QUw__"
    },
    {
    id:2,
    title:"Expertise",
    bgImg:"https://s3-alpha-sig.figma.com/img/bac2/aecc/5f664de9341a8354aa77a89a0f6a04f6?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Z9UnDKvk2ZhEAGmhLBTOhila1DuXpOrGsSV1UK1HbzDB~LQGMsPIDX8~p0s6tQvydDHUjpkh0~DwthQx1ass6O1s7c-jtg~2Rc5gXZZp557w-Gby09Uce4vKhKRzujh8R0Rz0eREKch409ByS4zdiRaMwc7WUQe0qiZ2fLF3fZ3t7pCMVxWwbjmIYiT~IIDQf3XHcYxeaEIgSR~QUczUTWr6GCAmSuahifIBRdxgTNTEn3kYGReR1IZIPy80fVY75YhgfID09EQIrx5rjrLIQB8Yeia6qrt9sc-bQBihohedpGUS1APzlAHIIcvRroXDKzaVniSOOZspBelkY9zYsw__"
    },
    {
    id:3,
    title:"Expertise",
    bgImg:"https://s3-alpha-sig.figma.com/img/1d79/2fbc/d4447bb88a7d0b03ab9f65fd4943f835?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QriDDvnhx6-Kdw9zN8MM8g-o1OWERqNAm6GpjNVfX6O1UjKq9fL-yVV70bVscHvO3N2nbGza6jzSRTf3xbWsLW9ghvf8TsIVvoH9giqUFZm7mkkZVQQ8V4jzkquLMW1Xt3MbdIc22xYTTgMDK~LHB~MwVUNzbkq-LLZhxe1Dt7OHJA38Y0uKnG9TX~~P9doRRZECRQNish3QypXeZe7IrHCzIxik6I~KLI9mjVsibZw0ZsfaWAdJj2o4KJvGNynTS9A-dCtsOc4OsNblsID2fl~RUUZWI28GgDOQim7EiPq6rWvU~aoiq8PNZXISKwKs~PNFSO0-EOA~Bj0tO5GZoA__"
    }
      ]
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    city: "",
    state: "",
    message: "",
  });

  

  function changeHandler(event) {
    const { name, value, checked, type } = event.target;

    setFormData((prevFormData) => {
      return {
        ...prevFormData,

        [name]: value,
      };
    });
  }

  function submitHandler(event) {
    event.preventDefault();
    //print
    console.log("Finally printing the entireform ka data ........");
    console.log(formData);
  }

  return (
    <div className="w-screen flex flex-col items-center justify-center mb-[100px]">
      <Hero_section
        title={data.title}
        backimg={data.backimg}
        para={data.para}
      />

      <div className="w-10/12 flex flex-col justify-center items-center gap-9 my-[70px] ">
        <div className=" mt-[100px] flex justify-between gap-10 items-center">
          <div className="w-[465x] h-[372px] flex flex-col gap-5 justify-center p-5 shadow-xl drop-shadow-2xl	 ">
            <h1 className="text-4xl font-semibold text-customColor-blue10">
              Our Office{" "}
            </h1>
            <h6 className="font-semibold">Gurugram Address</h6>
            <p className="leading-8 ">
              DLF Cyber Park, Tower C, 1st Floor, Phase II, Udyog Vihar, Sector
              20, Gurugram – 122008 Haryana, India Contact No.: 0124-6253200,
              0124-6253299
            </p>
            <p>Get Directions</p>
          </div>
          <div className="h-[413px] w-[666px]">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.8287337513766!2d77.28571567544252!3d28.634894683973556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb55c4f9ce65%3A0xdb2f629d6d6b4690!2sNirman%20Vihar%2C%20Preet%20Vihar%2C%20Delhi%2C%20110092!5e0!3m2!1sen!2sin!4v1707989177821!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="h-[413px] w-[666px]"
            ></iframe>
          </div>
        </div>

        <p className="mt-[10px]">
          If you would like to get in touch with us, please fill up the form
          below. We will get back to you within two working days and schedule an
          interaction.
        </p>
      </div>

      <div className="flex justify-center items-center  w-screen bg-customColor-blue9 ">
        <div className="w-10/12 flex flex-col gap-4 p-5">
          <h1 className="text-4xl font-bold text-white">Get in touch</h1>
          <p className="font-bold text-white">How Can We Help?</p>
          <p className="text-white">
            Write to us with your enquiries, questions or request a meeting to
            discuss your potential case.
          </p>

          <form onSubmit={submitHandler}>
            <div className=" flex flex-col gap-7 mt-[20px]">
              <div className="flex gap-5 ">
              <input
  type="text"
  placeholder="Full Name"
  onChange={changeHandler}
  name="name"
  value={formData.name}
  className="focus:outline-none border-b w-8/12 border-gray-300 bg-customColor-blue9 text-white px-4 py-2 placeholder-white"
/>

                <input
                  type="number"
                  placeholder="Contact Number"
                  onChange={changeHandler}
                  name="contactNumber"
                  value={formData.contactNumber}
                  class="focus:outline-none border-b w-8/12 border-gray-300 bg-customColor-blue9 px-4 py-2 text-white  placeholder-white"
                />
              </div>

              <div className="flex gap-5">
                <input
                  type="text"
                  placeholder="Email Address"
                  onChange={changeHandler}
                  name="email"
                  value={formData.email}
                  class="focus:outline-none border-b w-8/12 border-gray-300 bg-customColor-blue9 px-4 py-2 text-white  placeholder-white"
                />

                <input
                  type="text"
                  placeholder="City"
                  onChange={changeHandler}
                  name="city"
                  value={formData.city}
                  class="focus:outline-none border-b w-8/12 border-gray-300 bg-customColor-blue9 px-4 py-2 text-white  placeholder-white"
                />

                <input
                  type="text"
                  placeholder="State"
                  onChange={changeHandler}
                  name="state"
                  value={formData.state}
                  class="focus:outline-none border-b w-8/12 border-gray-300 bg-customColor-blue9 px-4 py-2 text-white  placeholder-white"
                />
              </div>

              <div>
                <input
                  type="text"
                  placeholder="Message"
                  onChange={changeHandler}
                  name="message"
                  value={formData.message}
                  class="focus:outline-none border-b w-full border-gray-300 bg-customColor-blue9 px-4 py-2 text-white  placeholder-white"
                />
              </div>
            </div>

            <div className="flex w-full items-center justify-start mt-[20px]  ">
              <button className="px-6 py-2  text-white hover:bg-customColor-blue10 border">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="more_about_us">
      <div className=" w-screen flex  items-center justify-center mt-[100px] ">
        <div className="flex items-center  gap-12  justify-center">
          <Horizontal_rule />
          <h1 className=" font-bold relative text-4xl ">More About Us</h1>
          <Horizontal_rule />
        </div>
      </div>
      </div>

      <div className="flex justify-center items-center gap-11 mt-[50px]">

{
  more_abot_data.map((data) => (
    <div className="h-[283px] w-[337px] flex items-center justify-center text-white text-2xl font-bold" key={data.id} style={{backgroundImage: `url(${data.bgImg})`}}>
      {data.title}
    </div>
  ))
}

</div>

    </div>
  );
};

export default Contactus;
