import React, { Children } from 'react'

const About_para_com = ({title,para} ) => {
  return (
    <div className='flex flex-col gap-[10px]'>

<h5 className='font-bold'>{title}</h5>
<p className='font-extralight leading-8 text-lg font-sans'>{para}</p>

</div>
  )
}

export default About_para_com