import React from 'react'
import { FaLinkedin ,FaYoutube} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {

const aboutus=['Sustain']
const insights=['Briefings','Briefings','Briefings', 'Briefings']
const quicklinks=['Briefings','Knowledge Repository','News and Insights', 'Privacy and Policy']

  return (
    <div className='w-screen bg-customColor-blue9 flex items-center justify-center py-10 text-white mt-[100px]'>
      <div className='w-11/12 flex  justify-around'>

<div className='w-5/12 text-white flex flex-col gap-2'>

      <p className='font-light leading-7'>Copyright © 2023 Touchstone Partners.
By accessing this website, you agree that you are seeking further information regarding Touchstone Partners of your own accord.  No part of this website should be construed as an advertisement of or solicitation for our professional services.</p>

<div>
  <h3 className='font-bold'>About Us</h3>
 {
  aboutus.map((data,index)=>(
    <div className='font-light' key={index}>
{data}
    </div>
  ))
 }

</div>

<div>
  <h3 className='font-bold'>Insights</h3>
 {
  insights.map((data,index)=>(
    <div className='font-light' key={index}>
{data}
    </div>
  ))
 }

</div>


</div>

<div>
  <h3 className='font-bold'>Quick Links</h3>
 {
  quicklinks.map((data,index)=>(
    <div className='font-light' key={index}>
{data}
    </div>
  ))
 }

</div>

<div className='flex flex-col gap-5'>
  <h4>SUBSCRIBE TO RECEIVE NEWS FROM US</h4>
  <div className="input-container flex items-center">
    <input
  type="text"
  placeholder="Enter email"
  name="email"
  className="focus:outline-none border h-[33px] w-[143px] border-gray-300 bg-customColor-blue9 text-white px-4 py-2 placeholder-white"
/>
    <button className="subscribe-button  h-[33px] text-black font-light px-2  bg-white">Subscribe</button>
</div>

<div className='flex gap-5'>
<FaLinkedin className='h-[37px] w-[37px]'/>
<FaYoutube className='h-[37px] w-[37px]'/>
<FaXTwitter className='h-[37px] w-[37px]'/>
</div>
</div>
</div>

    </div>
  )
}

export default Footer