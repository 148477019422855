import React from "react";
import Hero_section from "../components/common/Herosection";
import About_para_com from "../components/About_para_com";
import Horizontal_rule from "../components/Horizontal_rule";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Aboutus = () => {
  const data = {
    title: "About Us",
    para: "We are a leading full-service law firm in India that is consistently recognised for quality of services and client satisfaction.",
    backimg:
      "https://s3-alpha-sig.figma.com/img/1947/c766/6985684c5a34811022251efa3eedc382?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eUaPnPrPXC9Sk1C~3Dz6d7WqXCrTmzwjNQTlnwXkZBPcQl4VOsVig-qDpcLErUuEhfBYRBdj2FPuuOggvnDnL2-StieMo72vXffIndvlX9zlb1Kh0hbUWTDK-UcJRe4qsZ8lEHByE3R0XoagfCMddjbgzqobDZEH4vliFc1JTokndnk8eLfrm8oTrLX3~YqQxsjEACCxCpG4nMOVih7BRBGK3QN1PieEfBY5hTTMxzdKP-0TygIi2um0i-nLdU7qy0EUhKjDVcMY6mcUA6WNWyW0CLzuxiwfpy7vN0CST1cr0snGmxtxP2uiOpFem0UtdtRQucajA17yqlMnXmyYGg__",
  };

  const paraData = [
    {
      id: 1,
      title: "– Chambers and Partners, 2023",
      para: "Touchstone Partners possesses an accomplished group of lawyers in India, specialised in handling strategic M&A and joint ventures on behalf of renowned multinational clients from the financial, energy, technology and pharmaceutical industries. Draws on its extensive international experience to ably support prestigious international law firms on high-profile matters. Other clients include noted names from Europe, Asia and North America. Clients include Lanxess, ZPG Comparison Services and Huntsman International. Present in Mumbai, New Delhi and Bengaluru.",
    },
    {
      id: 2,
      title: "– Chambers and Partners, 2023",
      para: "Touchstone Partners possesses an accomplished group of lawyers in India, specialised in handling strategic M&A and joint ventures on behalf of renowned multinational clients from the financial, energy, technology and pharmaceutical industries. Draws on its extensive international experience to ably support prestigious international law firms on high-profile matters. Other clients include noted names from Europe, Asia and North America. Clients include Lanxess, ZPG Comparison Services and Huntsman International. Present in Mumbai, New Delhi and Bengaluru.",
    },
    {
      id: 3,
      title: "– Chambers and Partners, 2023",
      para: "Touchstone Partners possesses an accomplished group of lawyers in India, specialised in handling strategic M&A and joint ventures on behalf of renowned multinational clients from the financial, energy, technology and pharmaceutical industries. Draws on its extensive international experience to ably support prestigious international law firms on high-profile matters. Other clients include noted names from Europe, Asia and North America. Clients include Lanxess, ZPG Comparison Services and Huntsman International. Present in Mumbai, New Delhi and Bengaluru.",
    },
  ];

  const more_abot_data=[
{
id:1,
title:"Expertise",
bgImg:"https://s3-alpha-sig.figma.com/img/6ecf/a52e/523dcd994e8ffa11798c58c2a77aff3b?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FBowkB0o8aKIZGFsBsmfvR46MowFSduDzpNAmL5TopTiA8L~QmtNAZyaXCYrSDh0c8CAIfmtUUDLVGdI40AySjfiVG07WgHpsKJfkPft89M8C4Cwh71NvXXtqI6e9HPRCmYFhzT~ojN3PicJvx~OU7H5cJk3D6rT~RZO93exQIA~pLc64N002pLRB146-gsEoO3ZBhMzbN2zsThnJ6t3Qw2mJGO0d9lFbbKD71jXPOLA3TOnySTw70BlNSBiIjkHu-snXsrILfFS5zm-ml2hkD80seTekOFY1kt4gG1HluDhTHzINHHNFfK14NwMsZZUFsrdNsgjVu8hrpGsz53QUw__"
},
{
id:2,
title:"Expertise",
bgImg:"https://s3-alpha-sig.figma.com/img/bac2/aecc/5f664de9341a8354aa77a89a0f6a04f6?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Z9UnDKvk2ZhEAGmhLBTOhila1DuXpOrGsSV1UK1HbzDB~LQGMsPIDX8~p0s6tQvydDHUjpkh0~DwthQx1ass6O1s7c-jtg~2Rc5gXZZp557w-Gby09Uce4vKhKRzujh8R0Rz0eREKch409ByS4zdiRaMwc7WUQe0qiZ2fLF3fZ3t7pCMVxWwbjmIYiT~IIDQf3XHcYxeaEIgSR~QUczUTWr6GCAmSuahifIBRdxgTNTEn3kYGReR1IZIPy80fVY75YhgfID09EQIrx5rjrLIQB8Yeia6qrt9sc-bQBihohedpGUS1APzlAHIIcvRroXDKzaVniSOOZspBelkY9zYsw__"
},
{
id:3,
title:"Expertise",
bgImg:"https://s3-alpha-sig.figma.com/img/1d79/2fbc/d4447bb88a7d0b03ab9f65fd4943f835?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QriDDvnhx6-Kdw9zN8MM8g-o1OWERqNAm6GpjNVfX6O1UjKq9fL-yVV70bVscHvO3N2nbGza6jzSRTf3xbWsLW9ghvf8TsIVvoH9giqUFZm7mkkZVQQ8V4jzkquLMW1Xt3MbdIc22xYTTgMDK~LHB~MwVUNzbkq-LLZhxe1Dt7OHJA38Y0uKnG9TX~~P9doRRZECRQNish3QypXeZe7IrHCzIxik6I~KLI9mjVsibZw0ZsfaWAdJj2o4KJvGNynTS9A-dCtsOc4OsNblsID2fl~RUUZWI28GgDOQim7EiPq6rWvU~aoiq8PNZXISKwKs~PNFSO0-EOA~Bj0tO5GZoA__"
}
  ]

  return (
    <div>
      <Hero_section
        title={data.title}
        backimg={data.backimg}
        para={data.para}
      />
      <div className="flex justify-center items-center mt-20 ">
        <div className="flex bg-gray-200 p-10 rounded-lg">
          <div className="w-1/2 ">
            <h1 className="text-3xl font-semibold">About</h1>
            <p className="mt-4 text-customColor-blue10 text-xl">
              Founded in 2004, the Firm’s clear and singular purpose <br /> is
              to provide reliable, practical and full-service advice across{" "}
              <br />
              sectors. Our greatest strength is an in-depth <br />
              understanding of legal, regulatory and commercial <br />
              environments, in India and elsewhere.
            </p>
            <p className="mt-4 italic font-normal text-customColor-blue10">
              Our market-leading practices pioneer first-of-its-kind solutions
              in a <br /> spectrum of legal services across various industry
              sectors, helping us keep <br /> our clients Ahead of the Curve.
            </p>
          </div>

        

<div className='overflow-hidden h-[343px] w-[516px]'>
<div className="image-container"></div>
  
</div>

        </div>
      </div>

      <div className="para_section w-screen flex justify-center items-center mt-[100px]">
        <div className="w-11/12 flex flex-col gap-[10px] shadow-xl p-5 border-2 border-slate-100">
          <p className="font-normal leading-8 text-lg">
            "Touchstone Partners really understand the international aspects of
            what we need for transactions. They are also very used to working
            with international clients"
          </p>
          {paraData.map((item) => (
            <About_para_com key={item.id} title={item.title} para={item.para} />
          ))}
        </div>
      </div>

      <div className="Recognition">
        <div className=" w-screen flex  items-center justify-center mt-[100px] ">
          <div className="flex items-center  gap-12  justify-center">
            <Horizontal_rule />
            <h1 className=" font-bold relative text-4xl ">Recognition</h1>
            <Horizontal_rule />
          </div>
        </div>

        <div className="w-screen h-[251px] bg-customColor-lightgrey   mt-[70px] flex items-center justify-center">
          <div className="w-10/12  flex items-center justify-between">
            <IoIosArrowBack className="w-[44px] h-[44px]" />
            <div className="h-[123px] w-2/4  flex items-center justify-center font-light text-2xl ">
              <img
                src="https://s3-alpha-sig.figma.com/img/20f8/d721/dac349d8d1f5b1379d58a895e72de78f?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RDFid6BmyYU~ORyZtxkL-MMCAG-7u4Z-NsjGyBrBGPxKFe9TBS6RCiGicYfH3q12Z7U~MNVztIQv3Esm-FRZLXS9kheBZHmzjVfTOAHnd2DcVpOfavkWgXaOTN~VuUikY0Ls67QyY4-pMIkNEiBKOLQqcaD73OImEOgZl7FG9-wPnChAEA~nkkjBGc5P4VtYuNKq6ZkiEsi6-7XYZfSgWVj5J2yiupfux8CDoT~m73feHn9PY2Z6FVuAkHfvCxELLKHI6g7osD6YNj1ot~ybflKF~MLzMwQLAxuwkBYPzg6hbmuuFNP4TVTG~oMzS1mwkBaRshQibTpVNp4EOBqSEQ__"
                alt=""
                className="w-[153px] h-[123px]"
              />
              <div>
                <p>Recognised as the best law firm of the year </p>
                <div className="flex items-center text-base">
                  <p>Link :</p>
                  <p className="text-customColor-blue10">Read More</p>
                </div>
              </div>
            </div>

            <IoIosArrowForward className="w-[44px] h-[44px]" />
          </div>
        </div>
      </div>

<div className="more_about_us">
      <div className=" w-screen flex  items-center justify-center mt-[100px] ">
        <div className="flex items-center  gap-12  justify-center">
          <Horizontal_rule />
          <h1 className=" font-bold relative text-4xl ">More About Us</h1>
          <Horizontal_rule />
        </div>
      </div>
      </div>


<div className="flex justify-center items-center gap-11 mt-[50px]">

{
  more_abot_data.map((data) => (
    <div className="h-[283px] w-[337px] flex items-center justify-center text-white text-2xl font-bold" key={data.id} style={{backgroundImage: `url(${data.bgImg})`}}>
      {data.title}
    </div>
  ))
}

</div>

    </div>
  );
};

export default Aboutus;
