import React from 'react'
import Hero_section from '../components/common/Herosection';
import { IoIosSearch } from "react-icons/io";

const Ourpeople = () => {

    const data = {
        title: "Our People",
        para: "The firm’s success stems from our lawyers’ competence, their collaborative strengths, and our ability to build relationships. This is what has helped us win some of the most significant mandates across practice areas.",
        backimg:
          "https://s3-alpha-sig.figma.com/img/1947/c766/6985684c5a34811022251efa3eedc382?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eUaPnPrPXC9Sk1C~3Dz6d7WqXCrTmzwjNQTlnwXkZBPcQl4VOsVig-qDpcLErUuEhfBYRBdj2FPuuOggvnDnL2-StieMo72vXffIndvlX9zlb1Kh0hbUWTDK-UcJRe4qsZ8lEHByE3R0XoagfCMddjbgzqobDZEH4vliFc1JTokndnk8eLfrm8oTrLX3~YqQxsjEACCxCpG4nMOVih7BRBGK3QN1PieEfBY5hTTMxzdKP-0TygIi2um0i-nLdU7qy0EUhKjDVcMY6mcUA6WNWyW0CLzuxiwfpy7vN0CST1cr0snGmxtxP2uiOpFem0UtdtRQucajA17yqlMnXmyYGg__",
      };

const peopleData =[
    {
        id:1,
        image:"https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__",
        name:"Abhishek Singh",
        designation:"Managing Partners",
        ventures:"M&A | Joint Ventures | PE-VC Investments | General Corporate"
        
    },
    {
        id:2,
        image:"https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__",
        name:"Abhishek Singh",
        designation:"Managing Partners",
        ventures:"M&A | Joint Ventures | PE-VC Investments | General Corporate"
        
    },
    {
        id:3,
        image:"https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__",
        name:"Abhishek Singh",
        designation:"Managing Partners",
        ventures:"M&A | Joint Ventures | PE-VC Investments | General Corporate"
        
    },
    {
        id:4,
        image:"https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__",
        name:"Abhishek Singh",
        designation:"Managing Partners",
        ventures:"M&A | Joint Ventures | PE-VC Investments | General Corporate"
        
    },
    {
        id:5,
        image:"https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__",
        name:"Abhishek Singh",
        designation:"Managing Partners",
        ventures:"M&A | Joint Ventures | PE-VC Investments | General Corporate"
        
    },
    {
        id:6,
        image:"https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__",
        name:"Abhishek Singh",
        designation:"Managing Partners",
        ventures:"M&A | Joint Ventures | PE-VC Investments | General Corporate"
        
    },
    {
        id:7,
        image:"https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__",
        name:"Abhishek Singh",
        designation:"Managing Partners",
        ventures:"M&A | Joint Ventures | PE-VC Investments | General Corporate"
        
    },
    {
        id:8,
        image:"https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__",
        name:"Abhishek Singh",
        designation:"Managing Partners",
        ventures:"M&A | Joint Ventures | PE-VC Investments | General Corporate"
        
    },
]

const divStyle = {
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
  };

  return (
    <div>
  <Hero_section
        title={data.title}
        backimg={data.backimg}
        para={data.para}
      />

<div className=' w-screen p-10'>
<div className='flex items-center justify-start'>
      <input type="text" placeholder="Search for partner" class="focus:outline-none border-b w-8/12 border-gray-300  px-4 py-2"/>
        <IoIosSearch className='h-[17.5px] w-[17.5px]'/>
      </div>
</div>
 

<div className='grid grid-cols-4 justify-items-center'>

{peopleData.map((peopleData ,index) => (

    <div className="card_section  w-[248.4px] h-[353.04px]  my-10   hover:w-[260px] hover:h-[360.04px] transition-all duration-300 hover:shadow-2xl   " key={index} style={divStyle}>
    <div className='bg-customColor-lightBluish'>
        <img src={peopleData.image}  alt=""   />
    </div>
    <div className='h-2/6 bg-customColor-blue10 text-white p-2 flex flex-col justify-center items-start px-4' >
        <p className='font-bold text-base ' >{peopleData.name}</p>
        <p className='font-semibold text-sm'>{peopleData.designation}</p>
        <p className='font-normal text-sm'>{peopleData.ventures}</p>
    </div>

</div>

)) }

</div>

    </div>
  )
}


export default Ourpeople