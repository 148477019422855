import React from 'react'
import { FaArrowRight } from "react-icons/fa";

const Our_peopledetails = () => {

const nav=['About' , 'Recogniton' , 'Representive Matters' , 'Publications And Presentation']
const expertise=['Corporate and Commercial' , 'Private Equity' , 'Public M&A']
const professional_affi=['Bar Council of Maharashtra & Goa ' , 'Bar Council of Maharashtra & Goa ' ]
const education=['Magdalene College, University of Cambridge (MA LLB, First Class), 1995' ]
const qualification=['Solicitor, England and Wales, 1999', 'Admitted to the Bar Council of Maharashtra and Goa, 2001' ]
const recognition_accom=['The Economic Times and Spencer Stuart recognised him as one of the 40 under Forty Business Leaders for 2016.',
'He comes recommended by Chambers & Partners 2017, 2018 and 2019 for Private Equity, Chambers & Partners 2018 and 2019 for M&A, and IFLR 1000',
'He was one of the ‘Rising Stars – 40 under 40’, and a nominee for ‘Young Achievers Award of the Year Law Firm’, for 2016.'
]
const representative_matters=[

    {
        id:1,
        title:"Apax Partners",
        para:"Advised on: (a) buy-out of Healthium Medtech from TPG and CX Partners, (b) investment in iGATE Corporation and...",
        
    },
    {
        id:2,
        title:"The Blackstone Group",
        para:"Advised on: (a) buy-out of Healthium Medtech from TPG and CX Partners, (b) investment in iGATE Corporation and...",

    },
    {
        id:3,
        title:"Apax Partners",
        para:"Advised on: (a) buy-out of Healthium Medtech from TPG and CX Partners, (b) investment in iGATE Corporation and...",

    },

]
const publications=[

    {
        id:1,
        date:"19 Jan’24",
        para:"LexFlex offers unique opportunities for Legal Professionals: By Abhishek Singh",
        
    },
    {
        id:2,
        date:"19 Jan’24",
        para:"LexFlex offers unique opportunities for Legal Professionals: By Abhishek Singh",
        
    },
    {
        id:3,
        date:"19 Jan’24",
        para:"LexFlex offers unique opportunities for Legal Professionals: By Abhishek Singh",
        
    },
    {
        id:4,
        date:"19 Jan’24",
        para:"LexFlex offers unique opportunities for Legal Professionals: By Abhishek Singh",
        
    },
   

]



  return (
    <div className='w-screen flex flex-col items-center justify-center'>

        <div className='w-9/12'>

<div className='flex gap-8  '>

      {nav.map((data, index) => (
        <p key={index} className='text-customColor-blue10 cursor-pointer'>{data}</p>
      ))}
</div>


<div className='h-[458px] bg-gradient-to-b from-customColor-lineargradientbluish to-transparent flex mt-[20px]'>

<img src="https://s3-alpha-sig.figma.com/img/8bc9/acd2/11577a33b2d20a0301184b0303e48a4c?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=n9~5KTqsFJZx88NJ~mvoLjf5pqH65H3joZ24uSWlSEN6X3584UdlI6uOmfM5WfC3hUZ1WEMVmFxofMKXn1sUspswmHUqj15mAZKTjvzllpNGkFx1yy1A5IZSkD6KKa2jwHip9~2VDDgMIcFZac~Yxh4kpTp8DTa9Q1wppO1uAjFN8gUllXJRSXK5t3zvWq9JcnYwQTdjIgltZWyKFJHcsrk0RWiuW7sNoQiZ0uPsIXdRUrJ26Ot66vHP12UxezNxtT0-AiT1Q2XetkawKRYsF4HwiapvsxiPk0yNEnlEt8nJHl2JT6f6OObvMLbJPPdiiVtia3BHtYzmg1fxWh-s6g__" alt="" />
<div className='w-8/12 bg-customColor-blue9 h-[319px] p-7 flex flex-col justify-center gap-[10px] relative top-44 text-white'>
    
    <p className='font-semibold text-4xl'>Abhishek Singh, <span className='font-normal text-lg'>Delhi</span> </p> 
    <p className='font-semibold text-2xl'>Partner | Corporate and Commercial, Private Equity</p>
    <p className='font-normal text-xl'>Ranked in Band 1, Chambers & Partners, Corporate & M&A (Global) 2018, 2019, 2020, 2021, 2022 and 2023.</p>
    <div className='flex gap-6'>
    <p>T: +91 22 6636 5000 </p> <p>E: Write To Me</p>
    </div>
</div>

</div>

</div>



<div className='w-9/12 mt-[100px]'>
    <p className='mb-4'> <span className='font-semibold '> Abhishek</span> is Partner in Corporate practice. He has 15 years of post-qualification experience in general corporate and commercial matters. Abhishek has extensively worked on:</p>

<ul className='list-disc text-base flex flex-col gap-4'>
    <li><span className='font-semibold '>M&A and Joint Venture Transactions:</span>  Led several domestic, and cross-border, strategic M&A transactions and joint ventures.</li>
    <li><span className='font-semibold'>PE & VC Investments: </span>Advised on several investment transactions and diligences in a variety of sectors, including e-commerce, banking, manufacturing, telecom, pharma, education, and real estate.</li>
    <li><span className='font-semibold'>General Corporate Matters: </span>Regularly issuing legal opinions and solutions on complex matters of company laws, FDI regulations, RBI directions, and SEBI laws.</li>
    <li><span className='font-semibold'>Regulatory: </span>Liaised with prominent regulatory bodies such as the Reserve Bank of India, Ministry of Finance, Ministry of Commerce & Industry, and the Department of Telecom, Government of India, for approvals, clarifications, and policy work.</li>
</ul>

</div>


<div className='w-9/12 mt-[100px] flex flex-col gap-8'>

<div className='flex flex-col gap-4 '>
    <h1 className='font-bold text-2xl text-customColor-blue10'>Expertise</h1>
    <div className='flex gap-8  '>

{expertise.map((data, index) => (
  <p className='border p-3 rounded-2xl text-customColor-blue10' key={index}>{data}</p>
))}
</div>
</div>

<div className='flex flex-col gap-4 '>
    <h1 className='font-bold text-2xl text-customColor-blue10'>Profesional Affiliations</h1>
    <ul className='flex gap-8  list-disc'>

{professional_affi.map((data, index) => (
  <li className=' ' key={index}>{data}</li>
))}
</ul>
</div>

<div className='flex flex-col gap-4 '>
    <h1 className='font-bold text-2xl text-customColor-blue10'>Education</h1>

    <ul className='flex flex-col gap-3  list-disc'>
{education.map((data, index) => (
  <li className=' ' key={index}>{data}</li>
))}
</ul>
</div>

<div className='flex flex-col gap-4 '>
    <h1 className='font-bold text-2xl text-customColor-blue10'>Professional Qualifications</h1>
    <ul className='flex gap-8  list-disc'>

{qualification.map((data, index) => (
  <li className=' ' key={index}>{data}</li>
))}
</ul>
</div>


</div>


<div className=' h-[274px] w-full bg-customColor-lightgrey mt-[100px] flex items-center justify-center'>

<div className='w-9/12 flex flex-col gap-5'>
  <h1 className='font-bold text-2xl text-customColor-blue10'>Recognition and Accomplishments</h1>
  <div className='flex gap-5'>
    {recognition_accom.map((data, index) => (
      <p key={index} className={`flex-1 flex items-center justify-start ${index > 0 ? 'border-l border-customColor-blue10 border-solid border-l-10' : ''} pl-5`}>
        {data}
      </p>
    ))}
  </div>
</div>


</div>


<div className=' p-10 w-full bg-customColor-blue9 mt-[100px] flex items-center justify-center '>
<div className='w-9/12 flex flex-col gap-5 text-white'>
  <h1 className='font-bold text-2xl '>Representative Matters</h1>
  <p className=' '> <span className='font-semibold '>Abhishek Singh</span>  has represented and advised the following clients</p>

  <div className=' gap-5 grid grid-cols-3 justify-items-center'>
    {representative_matters.map((data) => (
     <div className='w-[313px] h-[219px] border border-gray-300 flex flex-col gap-5 p-5 text-white' key={data.id}>
        <h5 className='font-bold'>{data.title}</h5>
        <p>{data.para}</p>
       <div className='flex items-center justify-between'><p>Read More</p> <FaArrowRight/></div>
     </div>
    ))}
  </div>
</div>


</div>

<div className='w-9/12 flex flex-col gap-5 mt-[100px]'>
  <h1 className='font-bold text-2xl text-customColor-blue10'>Publications</h1>



  <div className=' gap-10 grid grid-cols-2 justify-items-center'>
    {publications.map((data) => (
     <div className='w-[474px] h-[219px] border border-gray-300 flex flex-col gap-5 p-7 shadow' key={data.id}>


 <div className='flex items-center justify-between'>
    <p className='rounded-full px-5 py-0 bg-customColor-skyblue text-customColor-blue9'>Article</p>
    <p className='font-light'>{data.date}</p>
 </div>
        <p className='font font-light'>{data.para}</p>
       <p className='font-semibold text-customColor-blue10 cursor-pointer '>Corporate and Commercial</p> 
     </div>
    ))}
  </div>


</div>





    </div>

  )
}

export default Our_peopledetails