import React from 'react'

const Hero_section = ({title , backimg,para}) => {
 

  const divStyle = {
    width: '100%', 
    height: '532px', 
    backgroundImage: `url(${backimg})`, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
  };

  return (
    <div className='w-screen h-[532px] text-white gap-10 font-bold' style={divStyle}>
      <div className='relative h-[100%] w-[100%] bg-black  bg-opacity-50 flex flex-col items-center justify-center gap-11 text-lg'> 
     <h1 className='text-5xl'>{title}</h1>
    <p className='w-3/5 text-center'> {para}</p>
    </div>
    </div>
  )
}

export default Hero_section