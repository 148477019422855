import React from 'react'
import { useState } from 'react';

const Join_team_form = () => {

    const [formData, setFormData] = useState(  {name: "", 
    email:"", contactNumber:"", qualification:"", completion:"", location:"",position:""
    ,preferredMonth:""} );
    function changeHandler(event) {

        const {name, value, checked, type} = event.target
    
    

        setFormData(prevFormData => {
          return {
            ...prevFormData,

            
            [name]:  value
          }
        
        });
      }  

      function submitHandler(event) {
        event.preventDefault();
        //print
        console.log("Finally printing the entireform ka data ........")
        console.log(formData)
      }

  return (
    <div className='flex  items-center justify-center w-screen'>

<div className='w-10/12 flex justify-between mt-[30px]'>


<div className='w-8/12 flex flex-col gap-5'>
<h1 className='text-4xl text-customColor-blue10 font-bold'>Join Our Team</h1>
<p className='leading-8 font-sans text-xl font-normal'>
Burgeon Law takes pride in the culture it has cultivated over the years. The collaborative work culture encourages and values contribution from all team who share common passion for curiosity, intellect, and leveraging potential. If you picture yourself working in a progressive work culture, surrounded by leaders who challenge you, support you, but also hone your skills to be the future leaders, take your next step with Burgeon Law we’ll make you a part of what’s next, what’s brewing and what holds the potential to be extraordinary. 
At Burgeon Law, you’ll get a chance to work with colleagues with diverse experience and from varied backgrounds, allowing you to cultivate close working relationships with peers and mentors you will continue to look up to, throughout your professional journey. Be a part of an environment that encourages creativity, collaboration and entrepreneurship in a hybrid working setup and progressive human resource development practices.
Submit your personal information and upload a copy of your resume.
You may share your resume at hr@burgeon.co.in
</p>
</div>

<div className='w-9/12  flex flex-col items-center justify-center  gap-7'>


<form onSubmit={submitHandler}>
<div className="flex flex-col gap-4 mt-[40px]">

<div>
    <p className='font-light'>Name</p>
      <input type="text" 
           placeholder='Full Name'
           onChange={changeHandler} 
           name="name" 
           value={formData.name}
      className="w-[401px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>E-Mail</p>
      <input type="text" 
        placeholder='E-Mail'
        onChange={changeHandler} 
        name="email" 
        value={formData.email}
      className="w-[401px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Contact number</p>
      <input type="text" 
         placeholder='Contact number'
         onChange={changeHandler} 
         name="contactNumber" 
         value={formData.contactNumber}
      className="w-[401px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Educational Qualification </p>
      <input type="text"
          placeholder='Educational Qualification '
          onChange={changeHandler} 
          name="qualification" 
          value={formData.qualification}
      className="w-[401px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Year of completion</p>
      <input type="text" 
         placeholder='Year of completion'
         onChange={changeHandler} 
         name="completion" 
         value={formData.completion}
      className="w-[401px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Preferred location of work</p>
      <input type="text"
         placeholder='Preferred location of work'
         onChange={changeHandler} 
         name="location" 
         value={formData.location}
      
      className="w-[401px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Position</p>
      <input type="text"
         placeholder='Position'
         onChange={changeHandler} 
         name="position" 
         value={formData.position}
      
      className="w-[401px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Preferred month for Starting</p>
      <input type="text"
         placeholder='Preferred month for starting'
         onChange={changeHandler} 
         name="preferredMonth" 
         value={formData.preferredMonth}
      
      className="w-[401px] border border-gray-300 rounded-md px-4 py-2" />
</div>



      
      <div className="flex items-center ">
      <label className="flex flex-col items-center px-4 py-2 bg-white text-blue-500 rounded-lg shadow-md tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
        <span className="text-base leading-normal">Choose a file</span>
        <input type="file" className="hidden" />
      </label>
    </div>
    </div>

    <div className='flex w-full items-center justify-center mt-[20px] '>
    <button className='px-6 py-3 bg-customColor-blue10 text-white hover:bg-customColor-blue9'>Submit</button>
    </div>

    </form>

</div>
</div>

    </div>
  )
}

export default Join_team_form