import React from 'react';
import { NavLink} from 'react-router-dom';

const Navbar = () => {
  return (
<div className='w-screen flex items-center justify-center p-6 sticky top-0 bg-white z-20'>
  <header className="bg-white text-black w-10/12 flex justify-between items-center ">
    <NavLink to ="/">
    <div className="logo bg-gray-300 h-16 w-48 text-xl font-bold uppercase "></div>
    </NavLink>

    <nav className="nav">
      <ul className="flex gap-5">
     
        <li className="mr-4">
          <NavLink to="/about-us" className="text-black text-xl hover:text-gray-600">About Us</NavLink>
        </li>
        <li className="mr-4">
          <NavLink to="/our-people" className="text-black text-xl hover:text-gray-600">Our People</NavLink>
        </li>
        <li className="mr-4">
          <NavLink to="/career" className="text-black text-xl hover:text-gray-600">Career</NavLink>
        </li>
        <li className="mr-4">
          <NavLink to="/our-investors" className="text-black text-xl hover:text-gray-600">Our Investors</NavLink>
        </li>
        <li>
          <NavLink to="/contact-us" className="text-black text-xl hover:text-gray-600">Contact Us</NavLink>
        </li>
      </ul>
    </nav>
  </header>
</div>

  );
};

export default Navbar;
