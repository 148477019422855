import React from 'react'
import { useState } from 'react';

const Internform = () => {

    const [formData, setFormData] = useState(  {name: "", 
    email:"", contactNumber:"", qualification:"", completion:"", location:"",currentYear:""
    ,preferredMonth:"",preferredYear:""} );
    function changeHandler(event) {

        const {name, value, checked, type} = event.target
    
    

        setFormData(prevFormData => {
          return {
            ...prevFormData,

            
            [name]:  value
          }
        
        });
      }  

      function submitHandler(event) {
        event.preventDefault();
        //print
        console.log("Finally printing the entireform ka data ........")
        console.log(formData)
      }

  return (
    <div className='flex flex-col items-center justify-center w-screen'>

<div className='w-9/12  flex flex-col items-center justify-center  gap-7'>
<h1 className='text-3xl text-customColor-blue10 font-bold'>INTERNSHIP APPLICATION FORM</h1>
<p className='text-center'>
While we will make every effort to accommodate your request as to preferred roles, dates and location, we cannot guarantee the same. To know more about our data privacy and storage policies, please click here. Good luck!
</p>

<form onSubmit={submitHandler}>
<div className="grid grid-cols-3 gap-4 mt-[40px]">

<div>
    <p className='font-light'>Name</p>
      <input type="text" 
           placeholder='Full Name'
           onChange={changeHandler} 
           name="name" 
           value={formData.name}
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>E-Mail</p>
      <input type="text" 
        placeholder='E-Mail'
        onChange={changeHandler} 
        name="email" 
        value={formData.email}
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Contact number</p>
      <input type="text" 
         placeholder='Contact number'
         onChange={changeHandler} 
         name="contactNumber" 
         value={formData.contactNumber}
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Educational Qualification </p>
      <input type="text"
          placeholder='Educational Qualification '
          onChange={changeHandler} 
          name="qualification" 
          value={formData.qualification}
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Year of completion</p>
      <input type="text" 
         placeholder='Year of completion'
         onChange={changeHandler} 
         name="completion" 
         value={formData.completion}
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Preferred location of work</p>
      <input type="text"
         placeholder='Preferred location of work'
         onChange={changeHandler} 
         name="location" 
         value={formData.location}
      
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>What is your current year of study?</p>
      <input type="text"
         placeholder='What is your current year of study?'
         onChange={changeHandler} 
         name="currentYear" 
         value={formData.currentYear}
      
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Preferred month for internship</p>
      <input type="text"
         placeholder='Preferred month for internship'
         onChange={changeHandler} 
         name="preferredMonth" 
         value={formData.preferredMonth}
      
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>
<div>
    <p className='font-light'>Preferred year for internship</p>
      <input type="text"
         placeholder='Preferred year for internship'
         onChange={changeHandler} 
         name="preferredYear" 
         value={formData.preferredYear}
      
      className="w-[340px] border border-gray-300 rounded-md px-4 py-2" />
</div>


      
      <div className="flex items-center ">
      <label className="flex flex-col items-center px-4 py-2 bg-white text-blue-500 rounded-lg shadow-md tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
        <span className="text-base leading-normal">Choose a file</span>
        <input type="file" className="hidden" />
      </label>
    </div>
    </div>

    <div className='flex w-full items-center justify-center mt-[20px] '>
    <button className='px-6 py-3 bg-customColor-blue10 text-white hover:bg-customColor-blue9'>Submit</button>
    </div>

    </form>

</div>

    </div>
  )
}

export default Internform