import React, { useState } from 'react';
import { FaArrowRight } from "react-icons/fa";

const Card = ({ title, para, backimg }) => {
    const [isHovered, setIsHovered] = useState(false);

    const cardTitleStyle = {
        backgroundImage: `url(${backimg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        overflow: 'hidden',
        opacity: isHovered ? '0.9' : '0.96',
        transform: isHovered ? 'scale(1.08)' : 'scale(1)',
        transition: 'opacity 0.5s ease, transform 0.3s ease',
        boxShadow: isHovered ? '0px 8px 15px rgba(0, 0, 0, 0.2)' : '0px 4px 6px rgba(0, 0, 0, 0.1)'
    };

    return (
        <div className='card bg-white shadow-lg rounded-lg overflow-hidden w-[337px] h-[463px] relative'>
            <div
                className="card-title w-[337px] h-[263.25px]"
                style={cardTitleStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <h2 className="text-white text-xl font-bold p-4">{title}</h2>
            </div>
            <div className="card-para-section p-4 ">
                <p className="text-gray-800 text-base font-medium">{para}</p>
                <div className="flex justify-between items-center mt-4 ">
                    <p className="text-gray-600 text-sm font-bold text-customColor-blue">Read More</p>
                    <FaArrowRight className="text-gray-600" />
                </div>
            </div>
        </div>
    );
};
export default Card;
