import "./App.css";
import { NavLink, Route, Routes } from 'react-router-dom';
import Homepage from "./pages/Homepage";
import MainHeader from "./components/MainHeader";
import Navbar from "./components/Navbar";
import Aboutus from "./pages/Aboutus";
import Ourpeople from "./pages/Ourpeople";
import Career from "./pages/Career"
import Our_peopledetails from "./components/common/Our_peopledetails";
import Internform from "./pages/Internform";
import Join_team_form from "./pages/Join_team_form";
import OurInvestors from "./pages/OurInvestors";
import Contactus from "./pages/Contactus";
import Footer from "./components/Footer";

function App() {
  return (
 <div>
<Navbar/>

  <Routes>
        <Route path="/" element={<MainHeader/>} >
          <Route index element={<Homepage/>} />
          <Route path="/about-us" element={<Aboutus/>} />
          <Route path="/our-people" element={<Ourpeople/>} />
          <Route path="/career" element={<Career/>} />
          <Route path="/ourpeopledetail" element={<Our_peopledetails/>} />
          <Route path="/internship-application-form" element={<Internform/>} />
          <Route path="/join-our-team" element={<Join_team_form/>} />
          <Route path="/our-investors" element={<OurInvestors/>} />
          <Route path="/contact-us" element={<Contactus/>} />
        </Route>
      </Routes>

<Footer/>
 </div>
  );
}

export default App;
