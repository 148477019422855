import React from 'react';
import Navbar from '../components/Navbar';
import Section4 from "../components/HomepageSection4";
import Section5 from '../components/HomepageSection5';
import image1 from '../assests/image1.jpeg'
import { useState } from 'react'
import  './homepage.css'
const Homepage = () => {

  

  return (
    <div>

      <div className=" text-white  h-[100%] flex flex-col justify-center  bg-cover bg-center bg-opacity-95"  style={{backgroundImage: `url(${image1})`  }}>

<div className='relative h-[568px] w-[100%] bg-black  bg-opacity-50'> 
<div className='px-40 p-10 '>
<h1 className="text-[72px] font-bold ">
    High-impact <br /> legal services
  </h1>
  <p className="mt-4 text-lg leading-10">
          Corporate & M&A <br /> Private Equity, Alternative Investments & Venture Capital <br /> Competition & Antitrust<br /> Employment, Pensions & Benefits <br /> Data Privacy & Security
        </p>
</div>
</div>



</div>


<div className="flex justify-center items-center mt-20 group h-[803px] w-screen">
  <div className="flex relative left-[80px] z-10 transition-all duration-300 h-[377px] w-[493px] hover:w-[480px] hover:h-[360px]">
    <img src="https://s3-alpha-sig.figma.com/img/15ee/a68e/eebb456774ed4bf240b03f40194f6ecd?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=l22ycSlO8W0CqV-x1HS5AG~qccJNar1SIdMIenujOtg9qQrJw-kjyruGBuLleHFpkkIRypl4nLWCuNPZsu04vFrm29zEzLUycPhaqukCxPUVWsJj6jyP3dlpZ~F2uxXO8UN-5PQGqYCTduC8zXW57b5cT8~bIxJuLTz6MUFnRdnLVCjCYc9jPu6kPqe9ahVoFom7mwTf~ft0Ehfde3ZpALDoKSN2Z~68uPcw9Ih5cNDkZojOm2RLYSX2XB5uOSCF6zxRLs4P6BUnbXoxh37na01OMX3E5fRqXIj1RYR0YI-DALd9DK1L9s0dfQsqzxAuLS5feX8egeb3sQTI2Si0FQ__" alt=""  />
  </div>
  <div className="flex flex-col justify-center items-center ml-20 bg-customColor-blue h-[483px] w-[803px] relative right-[80px] border-b shadow-2xl transition-all duration-300  hover:w-[780px] hover:h-[460px]">
    <div className="text-white">
      <h5 className="text-xl font-semibold text-start">Expertise</h5>
      <p className="mt-4">
        We are the largest full-service law firm in India, <br /> allowing us to deliver truly multidisciplinary advice <br /> for our clients’ business needs. <br />
        Our market-leading practices pioneer first-of-its-kind solutions <br /> in a spectrum of legal services across various industry sectors, <br /> helping us keep our clients Ahead of the Curve.
      </p>
      <p className="mt-4 cursor-pointer">Read More</p>
    </div>
  </div>
</div>






      <div className="flex justify-center items-center mt-20 ">
        <div className="flex bg-gray-200 p-10 rounded-lg">
          <div className="w-1/2 ">
            <h1 className="text-3xl font-semibold">About</h1>
            <p className="mt-4 text-customColor-blue10">
              Founded in 2004, the Firm’s clear and singular purpose <br /> is to provide reliable, practical and full-service advice across <br />
              sectors. Our greatest strength is an in-depth <br />
              understanding of legal, regulatory and commercial <br />
              environments, in India and elsewhere.
            </p>
            <p className="mt-4 italic font-normal text-customColor-blue10">
              Our market-leading practices pioneer first-of-its-kind solutions in a <br /> spectrum of legal services across various industry sectors, helping us keep <br /> our clients Ahead of the Curve.
            </p>
          </div>




<div className='overflow-hidden'>
<div className="image-container"></div>
  
</div>



        </div>
      </div>

      <Section4 />
      <Section5 />
<div className='w-screen flex flex-col items-center justify-center'>

      <div className=" text-white w-11/12  h-[447px] flex flex-col justify-center  bg-cover bg-center bg-opacity-95"  style={{ backgroundImage: 'url(https://trilegal.com/wp-content/themes/trilegal/assets/images/sustain.jpg)' }}>
<div className='relative h-[568px] w-[100%] bg-black  bg-opacity-50 flex flex-col justify-center items-center'> 
          <h2 className="text-3xl font-semibold">COMMITMENT TO SUSTAINABILITY</h2>
          <button className="mt-4 px-4 py-2 border border-white rounded-md hover:bg-white hover:text-purple-700">Read More</button>
</div>
</div>
</div>

    </div>
  );
}

export default Homepage;
