import React from 'react';
import { useState } from 'react';
import { FaArrowRight } from "react-icons/fa";

const Card_sector = ({ title, backimg }) => {

    const [isHovered, setIsHovered] = useState(false);
    const cardTitleStyle = {
        backgroundImage: `url(${backimg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        opacity: isHovered ? '0.9' : '0.96',
        transform: isHovered ? 'scale(1.08)' : 'scale(1)',
        transition: 'opacity 0.5s ease, transform 0.3s ease',
        boxShadow: isHovered ? '0px 8px 15px rgba(0, 0, 0, 0.2)' : '0px 4px 6px rgba(0, 0, 0, 0.1)'
    };

    return (
        <div className='card-sector  bg-white shadow-lg rounded-lg overflow-hidden w-[337px] h-[415px]'>
       <div
                className="card-title w-[337px] h-[263.25px]"
                style={cardTitleStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <h2 className="text-white text-xl font-bold p-4">{title}</h2>
            </div>
            <div className="card-sector-para-section p-5 flex flex-col justify-center items-center relative ">
                <p className="text-xl font-2xl font-bold text-center mb-4">{title}</p>
                <div className="flex justify-center items-center w-full gap-3 ">
                    <p className="text-sm text-gray-700">Learn More</p>
                    <FaArrowRight className="text-gray-700" />
                </div>
            </div>
        </div>
    );
};

export default Card_sector;
