import React from 'react'

const Horizontal_rule = () => {
  return (
    <div>
        <div className=' h-0 w-[511px] border border-slate-200'></div>
    </div>
  )
}

export default Horizontal_rule